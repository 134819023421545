<template>
  <div id="print-pallet-flag">
    <tabbed-display
      :tabs="tabs"
      :data="data"
      @finished="finished"
      @paging="paging"
      @quited="quited"
      v-if="loaded"
    ></tabbed-display>
    <loading v-else />
  </div>
</template>

<script>
import { Notification } from "@/helpers/notifications";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { execute_json } from "@/helpers/requests";
import { getStorage, setStorage } from '@/helpers/storage';
export default {
  data() {
    return {
      data: {
        order: {
          selected: null,
        },
        employee: {
          value: null,
          fullname: null,
        },
        goodquantity: {
          value: null,
          allowNull: true,
        },
        scrapquantity: {
          value: null,
          allowNull: true,
        },
        dates: {
          values: [],
          numDates: 0,
        },
        print: {
          num_copies: 1,
          printer: null,
        },
        multiprint: {
          prodquantity: [],
          goodquantity: [],
          scrapquantity: [],
          num_copies: 1,
          printer: null,
        },
      },
      activities: [],
      loaded: false,
    };
  },
  created() {
    if (
      this.bottomNav.type === "running-order-singular" &&
      this.selections.order
    ) {
      this.data.order.selected = this.selections.order;
    }
    store.commit("bottomnav/disable");
    this.loaded = false;
    let promises = [];
    promises.push(
      store.dispatch("machines/loadSignedInForMachine", this.machine_id)
    );
    promises.push(
      store.dispatch("machines/loadTouchConfigForMachine", {
        machine_id: this.machine_id,
      })
    );
    if (this.hasSf386){
      this.data.employee.value = this.username;
    }
    if (this.hasSf195){
      this.data.goodquantity.value = getStorage(this.sf195StorageString) || null;
    }
    Promise.all(promises).then(() => {
      this.loaded = true;
      this.data.print.printer = this.machine_config.pallet_flag_printer;
      console.log("fully loaded..");
    });
    store.dispatch("printers/loadPrinters");
  },
  methods: {
    quited() {
      this.$router.push(this.$route.path.replace("/print-pallet-flag", ""));
    },
    finished() {
      if (this.splitPalletFlagReporting) {
        return this.splitFinish();
      }
      if (this.hasSf195){
        console.log("setting storage");
        setStorage(this.sf195StorageString,this.data.goodquantity.value);
      }
      let n = Notification.request({
        message: "Printing palletflag",
      });
      let dates = this.keyedDates();
      execute_json("print_pallet_flag", {
        card_no: this.data.order.selected.card_no,
        good_qty: this.data.goodquantity.value,
        scrap_qty: this.data.scrapquantity.value,
        employee: this.data.employee.value,
        number_of_copies: this.data.print.num_copies,
        pallet_flag_printer: this.data.print.printer,
        machine_id: this.machine_id,
        pallet_flag_type: "pallet_flag",
        ...dates,
      })
        .then(() => {
          console.log("wrapping up with another call");
          n.ok({
            message: "ok!",
          });
          this.quited();
        })
        .catch((err) => {
          n.fail({
            message: err.statusText,
          });
        });
    },
    splitFinish() {
      let max = this.data.multiprint.goodquantity.length;
      let n = Notification.request({
        header: "Printing " + max + " palletflag(s)",
        message: "Printing " + max + " palletflag(s)",
      });
      this.ship(0, max)
        .then(() => {
          n.ok({
            message: "done",
          });
          this.quited();
        })
        .catch((err) => {
          n.fail({
            message: err.statusText,
          });
        });
    },
    ship(i, max) {
      return new Promise((resolve, reject) => {
        let card_no = this.data.order.selected.card_no;
        let good_qty = this.data.multiprint.goodquantity[i];
        let scrap_qty = this.data.multiprint.scrapquantity[i];
        let produced_qty = this.data.multiprint.prodquantity[i];
        let employee = this.data.employee.value;
        let number_of_copies = this.data.multiprint.num_copies;
        let pallet_flag_printer = this.data.multiprint.printer;
        execute_json("print_pallet_flag", {
          card_no,
          good_qty,
          scrap_qty,
          produced_qty,
          employee,
          number_of_copies,
          pallet_flag_printer,
          machine_id: this.machine_id,
        })
          .then((data) => {
            i++;
            if (i === max) {
              resolve();
            } else {
              this.ship(i, max)
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    paging() {},
    keyedDates() {
      let dates = {};
      if (this.data.dates.numDates > 0) {
        for (let i = 0; i < this.data.dates.numDates; i++) {
          let key = "dtSF140_" + (i + 1);
          dates[key] = new Date(this.data.dates.values[i]).toBalthzarTime();
        }
      }
      return dates;
    },
  },
  computed: {
    ...mapState({
      bottomNav: (state) => state.bottomnav,
      notifications: (state) => state.notifications.index,
      username: (state) => state.auth?.username,
      companyId:state => state.auth?.company_id,
      selections: (state) => state.selections,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    ...mapGetters({
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_config() {
      return this.configByMachine(this.machine_id);
    },
    splitPalletFlagReporting() {
      return this.machineHasSf(this.machine_id, "109");
    },
    hasSf362() {
      return this.machineHasSf(this.machine_id, "362");
    },
    hasSf337() {
      return this.machineHasSf(this.machine_id, "337");
    },
    hasSf386() {
      return this.machineHasSf(this.machine_id, "386");
    },
    hasSf195() {
      return this.machineHasSf(this.machine_id, "195");
    },
    sf195StorageString(){
      return this.companyId+'-'+this.machine_id+'-sf195value'
    },
    tabs() {
      let tabs = [];
      //do a check to see if we need an order
      let initialShow = this.data.order.selected === null;
      tabs.push({
        label: "order",
        tlabel: "24",
        component: "running-order",
        disabled: false,
        initialShow: initialShow,
        dataKey: "order",
      });
      tabs.push({
        label: "employee",
        tlabel: "578",
        component: "employee",
        initialShow: true,
        disabled: this.hasSf386,
        dataKey: "employee",
      });
      tabs.push({
        label: "quantity",
        tlabel: "581",
        component: "quantity",
        disabled: this.splitPalletFlagReporting,
        guide: "!SF:109",
        initialShow: 1,
        dataKey: "goodquantity",
        sideComponent: this.hasSf362 ?  (this.hasSf337 ? "pallet-history"  : 'good-history') : null,
      });
      tabs.push({
        label: "quantity",
        tlabel: "554",
        component: "quantity",
        disabled:
          this.splitPalletFlagReporting ||
          this.machineHasSf(this.machine_id, "139"),
        guide: this.splitPalletFlagReporting ? "!SF:109" : "!SF: 139",
        initialShow: 1,
        dataKey: "scrapquantity",
      });
      tabs.push({
        label: "dates",
        //tlabel: "574",
        component: "t-date",
        disabled:
          this.splitPalletFlagReporting ||
          !this.machineHasSf(this.machine_id, "140"),
        guide: this.splitPalletFlagReporting ? "!SF:109" : "SF: 140",
        initialShow: 0,
        dataKey: "dates",
      });
      tabs.push({
        label: "print",
        //tlabel: "574",
        component: "print",
        disabled: this.splitPalletFlagReporting,
        initialShow: 0,
        dataKey: "print",
        guide: "!SF:109",
        reporter: [
          {
            tlabel: "24",
            val: this.data.order.selected
              ? this.data.order.selected.work_order_no
              : "-",
          },
          {
            tlabel: "578",
            val: this.data.employee.value,
          },
          {
            tlabel: "581",
            val: this.data.goodquantity.value,
          },
          {
            tlabel: "554",
            val: this.data.scrapquantity.value,
            showIfNull: false,
          },
        ],
      });
      tabs.push({
        label: "print",
        //tlabel: "574",
        component: "split-pallet-print",
        disabled: !this.splitPalletFlagReporting,
        guide: "SF:109",
        initialShow: 0,
        dataKey: "multiprint",
        reporter: [
          {
            tlabel: "24",
            val: this.data.order.selected
              ? this.data.order.selected.work_order_no
              : "-",
          },
          {
            tlabel: "578",
            val: this.data.employee.value,
          },
          {
            tlabel: "581",
            val: this.data.goodquantity.value,
          },
          {
            tlabel: "554",
            val: this.data.scrapquantity.value,
            showIfNull: false,
          },
        ],
      });
      return tabs;
    },
  },
  watch: {
    $route(to) {
      if (this.loaded && to.params.machine) {
        this.loaded = false;
        let promises = [];
        promises.push(
          store.dispatch("machines/loadSignedInForMachine", to.params.machine)
        );
        promises.push(
          store.dispatch("machines/loadTouchConfigForMachine", {
            machine_id: to.params.machine,
          })
        );
        Promise.all(promises).then(() => {
          this.loaded = true;
          console.log("fully loaded..");
        });
      }
    },
  },
};
</script>

<style>
</style>